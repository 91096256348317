<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">福卡管理</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-table border class="mt-3" :data="lists" style="width: 100%">
				<el-table-column align="center" width="120" prop="focas_id" label="福卡ID"></el-table-column>
				<el-table-column align="center" prop="name" label="名称"></el-table-column>
				<el-table-column align="center" label="福卡图">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.image">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="150">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="openDialog(scope.row)">修改</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" top="3vh" width="650px" height="60%">
				<!-- 修改规则 -->
				<el-form :rules="rules" ref="form" :model="form" label-width="100px">
					<el-form-item label="福卡名称" prop="name">
						<el-input v-model="form.name" placeholder="名称"></el-input>
					</el-form-item>
					<el-form-item label="图片" prop="image">
						<app-attachment :multiple="false" :max="1" v-model="form.image">
							<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
						</app-attachment>
						<app-gallery :url="form.image" :show-delete="true" @deleted="form.image = ''" width="80px"
							height="80px"></app-gallery>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submit('form')">确 定</el-button>
					<el-button @click="dialogVisible = false">取 消</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	export default {
		inject: ['layout'],
		components: {
			appRichText
		},
		data() {
			return {
				activeName: 'basic',
				dialogVisible: false,
				dialogTitle: '编辑福卡',
				lists: [],
				form: {
					name: '',
					image: '',
				},
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
				},
				search: {
					s: 'store/focas/index',
				},
				focas_id: 0
			}
		},
		created() {
			this.getList();
		},
		methods: {
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						this.axios({
							token: true,
							params: {
								s: 'store/focas/edit',
								focas_id: this.focas_id,
							},
							data: {
								focas: this.form
							},
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.dialogVisible = false;
								this.getList()
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},

			// 打开弹出层
			openDialog(item = false) {
				this.axios({
					token: true,
					params: {
						focas_id: item.focas_id,
						s: 'store/focas/edit'
					},
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						this.form = {
							name: item.name,
							image: item.image,
						}
						this.focas_id = item.focas_id
					} else {
						return false;
					}
				}).catch(err => {})
				// 显示弹出层
				this.dialogVisible = true
			},

			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>
<style>

</style>
